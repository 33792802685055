import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { BusinessDetails } from './BusinessDetails';
import { AdditionalInfo } from './AdditionalInfo';
import { AccessInfo } from './AccessInfo';
import { SquarePaymentGateway } from './SquarePaymentGateway';
import { BankDetails } from './BankDetails';
import { TaxDetails } from './TaxDetails';
import { RegistrationDetails } from './RegistrationDetails';
import axios from "axios";
import '../../../pages/Common/styles.css';
import { Features } from '../FeaturesManagement/Features';
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import TextField from '@mui/material/TextField';
import { Modal } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import { store } from '../../../redux/store';
import BusinessLogoUpload from '../../Common/BusinessLogoUpload';
import BorderedSection from '../../Common/BorderedSection';

const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    border: 1,
    ml: -1.5,
    mb: 1.5,
    p: 1
};

function CreateBusiness() {
    const navigate = useNavigate();

    const [accessCodeError, setAccessCodeError] = useState(false);
    const createBusinessURL = process.env.REACT_APP_SERVICE_URI + "businesses/";
    const featuresURL = process.env.REACT_APP_SERVICE_URI + "features/";
    const uploadBusinessImageURL = process.env.REACT_APP_SERVICE_URI + "files/images/upload/";

    const [availableFeatures, setAvailableFeatures] = useState<Features[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);

    const [businessDetails, setBusinessDetails] = useState<BusinessDetails>();
    const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>();
    const [accessInfo, setAccessInfo] = useState<AccessInfo>();
    //const [squarePaymentGateway, setSquarePaymentGateway] = useState<SquarePaymentGateway>();
    const [bankDetails, setBankDetails] = useState<BankDetails>();
    const [taxDetails, setTaxDetails] = useState<TaxDetails>();
    const [registrationDetails, setRegistrationDetails] = useState<RegistrationDetails>();

    const [businessLogo, setBusinessLogo] = useState<File| null>(null);
    const businessLogoData = new FormData();

    const storeData = store.getState();

    const getFeatures = async (config: any) => {
        try {
            const response = await axios.get(featuresURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);
        
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;        
            getFeatures(getConfig(accessToken))
            .then((data) => {
                setAvailableFeatures(data);
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });
        })
    },[]);

    const handleOnChangeFeatures = (event: any) => {
        const title = event.target.value;
        const feature_id= event.target.id;
        const isChecked = event.target.checked;

        if(feature_id === 'selectAll') {
            if (isChecked) {
                setSelectedFeatures(prevState => []);
                availableFeatures.map(availableFeature => {
                    let feature = Object.assign({}, {feature_id: availableFeature.id, title: availableFeature.title});
                    setSelectedFeatures(prevState => [...prevState, feature])
                })
            } else {
                setSelectedFeatures(prevState => []);
            }
        } else{
            if (isChecked) {
                setSelectedFeatures([...selectedFeatures, {'feature_id': feature_id, 'title': title}])
            } else {
                const filteredList = selectedFeatures.filter((item) => item.feature_id !== feature_id);
                setSelectedFeatures(filteredList);
            }
        }
    }

    const handleBusinessDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "business_name") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, business_name:event.target.value}
            });
        } else if (item === "business_website_url") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, business_website_url:event.target.value}
            });
        } else if (item === "edit_product_pin") {
            const inputText = event.target.value;
            const maxLength = 4;
            if (inputText.length <= maxLength) {
                setBusinessDetails((previousState: any) => {
                    return {...previousState, edit_product_pin:event.target.value}
                }); 
                setAccessCodeError(false);
            } else {
                setAccessCodeError(true);
            }
        } else if (item === "is_dealer") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, is_dealer:event.target.checked}
            });
        } else if (item === "is_nixconnect_business") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, is_nixconnect_business:event.target.checked}
            });
        } 
        else if (item === "address_line_1") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, address_line_1:event.target.value}
            });
        } else if (item === "address_line_2") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, address_line_2:event.target.value}
            });
        } else if (item === "city") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, city:event.target.value}
            });
        } else if (item === "state") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, state:event.target.value}
            });
        } else if (item === "country") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, country:event.target.value}
            });
        } else if (item === "post_code") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, post_code:event.target.value}
            });
        } else if (item === "email") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, email:event.target.value}
            });
        } else if (item === "mobile") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, mobile:event.target.value}
            });
        } else if (item === "landline") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, landline:event.target.value}
            });
        } else if (item === "general_message") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, general_message:event.target.value}
            });
        }
    }

    const handleAdditionalInfoChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "currency_code") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, currency_code:event.target.value}
            });
        } else if (item === "currency_symbol") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, currency_symbol:event.target.value}
            });
        } else if (item === "notes") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, notes:event.target.value}
        });
        } else if (item === "message") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, message:event.target.value}
            });
        } else if (item === "remarks") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, remarks:event.target.value}
            });
        } else if (item === "return_policy") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, return_policy:event.target.value}
            });
        } else if (item === "thank_you_message") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, thank_you_message:event.target.value}
            });
        }
    }

    const handleAccessInfoChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "key") {
            setAccessInfo((previousState: any) => {
                return {...previousState, key:event.target.value}
        });
        } else if (item === "secret") {
            setAccessInfo((previousState: any) => {
                return {...previousState, secret:event.target.value}
            });
        } else if (item === "consumer_pass") {
            setAccessInfo((previousState: any) => {
                return {...previousState, consumer_pass:event.target.value}
            });
        } else if (item === "name") {
            setAccessInfo((previousState: any) => {
                return {...previousState, name:event.target.value}
            });
        } else if (item === "base_url") {
            let baseUrl = event.target.value;
            if (baseUrl[0] === '/') {
                baseUrl = baseUrl.substring(1);
            }
            setAccessInfo((previousState: any) => {
                return {...previousState, base_url:baseUrl}
            });
        } else if (item === "base_path") {
            setAccessInfo((previousState: any) => {
                return {...previousState, base_path:event.target.value}
            });
        } else if (item === "base_version") {
            setAccessInfo((previousState: any) => {
                return {...previousState, base_version:event.target.value}
            });
        } else if (item === "port") {
            setAccessInfo((previousState: any) => {
                return {...previousState, port:Number(event.target.value)}
            });
        } else if (item === "is_active_business") {
            setAccessInfo((previousState: any) => {
                return {...previousState, is_active_business:event.target.checked}
            });
        } else if (item === "is_banned_business") {
            setAccessInfo((previousState: any) => {
                return {...previousState, is_banned_business:event.target.checked}
            });
            if(event.target.checked) {
                setAccessInfo((previousState: any) => {
                    return {...previousState, is_active_business:false}
                });
            }
        }
    }

    // Set Square Payment realted
    // const handleSquareGatewayChange = (
    //     event: React.ChangeEvent<HTMLInputElement>,
    //     item: string
    //   ) => {
    //     setSquarePaymentGateway((previousState: any) => {
    //       return {
    //         ...previousState,
    //         [item]: item === "is_active" ? event.target.checked : event.target.value,
    //       };
    //     });
    //   };

    // const handleSquareGatewayChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    //     if (item === "base_url") {
    //         setSquarePaymentGateway((previousState: any) => {
    //             return {...previousState, base_url:event.target.value}
    //         });
    //     } else if (item === "base_path") {
    //         setSquarePaymentGateway((previousState: any) => {
    //             return {...previousState, base_path:event.target.value}
    //         });
    //     } else if (item === "application_id") {
    //         setSquarePaymentGateway((previousState: any) => {
    //             return {...previousState, application_id:event.target.value}
    //         });
    //     } else if (item === "access_token") {
    //         setSquarePaymentGateway((previousState: any) => {
    //             return {...previousState, access_token:event.target.value}
    //         });
    //     } else if (item === "is_active") {
    //         setSquarePaymentGateway((previousState: any) => {
    //             return {...previousState, is_active:event.target.checked}
    //         });
    //     }
    // }

    const handleBankDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "bank_name") {
            setBankDetails((previousState: any) => {
                return {...previousState, bank_name:event.target.value}
        });
        } else if (item === "account_holder_name") {
            setBankDetails((previousState: any) => {
                return {...previousState, account_holder_name:event.target.value}
            });
        } else if (item === "account_number") {
            setBankDetails((previousState: any) => {
                return {...previousState, account_number:event.target.value}
            });
        } else if (item === "bsb") {
            setBankDetails((previousState: any) => {
                return {...previousState, bsb:event.target.value}
            });
        } else if (item === "routing_number") {
            setBankDetails((previousState: any) => {
                return {...previousState, routing_number:event.target.value}
            });
        } else if (item === "swift_code") {
            setBankDetails((previousState: any) => {
                return {...previousState, swift_code:event.target.value}
            });
        } else if (item === "iban") {
            setBankDetails((previousState: any) => {
                return {...previousState, iban:event.target.value}
            });
        } else if (item === "branch_name") {
            setBankDetails((previousState: any) => {
                return {...previousState, branch_name:event.target.value}
            });
        } else if (item === "address") {
            setBankDetails((previousState: any) => {
                return {...previousState, address:event.target.value}
            });
        } else if (item === "pay_id_email") {
            setBankDetails((previousState: any) => {
                return {...previousState, pay_id_email:event.target.value}
            });
        } else if (item === "pay_id_mobile") {
            setBankDetails((previousState: any) => {
                return {...previousState, pay_id_mobile:event.target.value}
            });
        } else if (item === "upi_payment_id") {
            setBankDetails((previousState: any) => {
                return {...previousState, upi_payment_id:event.target.value}
            });
        } else if (item === "pay_id_other") {
            setBankDetails((previousState: any) => {
                return {...previousState, pay_id_other:event.target.value}
            });
        }
    }

    const handleTaxDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "category_one") {
            setTaxDetails((previousState: any) => {
                return {...previousState, category_one:event.target.value}
            });
        } else if (item === "value_one") {
            setTaxDetails((previousState: any) => {
                return {...previousState, value_one:event.target.value}
        });
        } else if (item === "category_two") {
            setTaxDetails((previousState: any) => {
                return {...previousState, category_two:event.target.value}
            });
        } else if (item === "value_two") {
            setTaxDetails((previousState: any) => {
                return {...previousState, value_two:event.target.value}
            });
        }
        else if (item === "is_composition_tax_scheme") {
            setTaxDetails((previousState: any) => {
                return {...previousState, is_composition_tax_scheme:event.target.checked}
            });
        } 
    }

    const handleRegistrationDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "company_number") {
            setRegistrationDetails((previousState: any) => {
                return {...previousState, company_number:event.target.value}
        });
        } else if (item === "company_other_details") {
            setRegistrationDetails((previousState: any) => {
                return {...previousState, company_other_details:event.target.value}
            });
        } else if (item === "dealer_license_number") {
            setRegistrationDetails((previousState: any) => {
                return {...previousState, dealer_license_number:event.target.value}
            });
        } 

        
    }

    const handleCreateBusiness = (event: any) => {
        event.preventDefault();
        setIsFetching(prevState => !prevState)

        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;

            axios.post(createBusinessURL, {
                nix_connect_app_profiles: selectedFeatures,
                company_business_profile: {
                    business_details: businessDetails,
                    additional_info: additionalInfo? additionalInfo : null,
                    access_info: accessInfo,
                    bank_details: bankDetails? bankDetails : null,
                    //square_payment_gateway: squarePaymentGateway,
                    tax_details: taxDetails? taxDetails : null,
                    registration_details: registrationDetails? registrationDetails : null
                }
            }, getConfig(accessToken))
            .then((response) => {
                const message = response.data.message;
                const businessId = response.data.business_id;
                if (message.toUpperCase() === 'Success'.toUpperCase()) {
                    if (businessId && businessLogo) {
                        businessLogoData.append("business_logo", businessLogo as File);
                        businessLogoData.append("business_id", businessId as string);
    
                        const config = getConfig(accessToken);
                        //@ts-ignore
                        config.headers['Content-Type'] = 'multipart/form-data';
                        axios.post(uploadBusinessImageURL, businessLogoData, config)
                        .then((response) => {
                            if (response.data.message.toUpperCase() === 'Success'.toUpperCase()) {
                                let additionalInfoToPatch: AdditionalInfo = additionalInfo || ({} as any) as AdditionalInfo;
                                additionalInfoToPatch['business_logo_name'] = response.data.business_logo_name;
                                additionalInfoToPatch['business_logo_path'] = response.data.public_url;
                                additionalInfoToPatch['business_logo_size'] = response.data.business_logo_size;
                                axios.patch(createBusinessURL + businessId + "/", 
                                {
                                    nix_connect_app_profiles: selectedFeatures,
                                    company_business_profile: {
                                        business_details: businessDetails,
                                        additional_info: additionalInfoToPatch? additionalInfoToPatch : null,
                                        access_info: accessInfo,
                                        bank_details: bankDetails? bankDetails : null,
                                        //square_payment_gateway: squarePaymentGateway,
                                        tax_details: taxDetails? taxDetails : null,
                                        registration_details: registrationDetails? registrationDetails : null
                                    }
                                }, getConfig(accessToken))
                                .then((response) => {
                                    alert('Successfully created new business');
                                    setIsFetching(false);
                                    window.location.reload();
                                })
                                .catch((error) => {
                                    setIsFetching(false);
                                    alert('Unable to register. Please try after some time.');
                                })
                            } else {
                                setIsFetching(false);
                                alert('Unable to register. Please try after some time.');
                            }
                        })
                        .catch((error) => {
                            setIsFetching(false);
                            alert('Unable to register. Please try after some time.');
                        })
                    } else {
                        alert('Successfully created new business');
                        setIsFetching(prevState => !prevState)
                        window.location.reload();
                    }
                } else {
                    setIsFetching(false);
                    alert('Please enter valid details');
                    navigate("/admin/create-business");
                }
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Please enter valid details');
                navigate("");
            });
        })
    }

    return (
        <>
            <SuperAdminNavbar />
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                }}
            >
                <CircularProgress />
            </Modal>
            <div className='ag-grid-size'>
                <div className='ms-4'>
                    <form onSubmit={handleCreateBusiness}>
                        <h3 style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>Create Business</h3>
                        <label htmlFor='businessDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Business Details</label>
                        <label htmlFor='businessDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Required)</label>

                        <div id='businessDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "business_name")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Website URL" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "business_website_url")} variant="outlined" />
                                {/* <TextField id="outlined-controlled" className="form-control me-3 col" label="Product Access Code (4 Digits)" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "edit_product_pin")} required variant="outlined" /> */}
                                <TextField
                                    id="outlined-controlled"
                                    className="form-control me-3 col"
                                    label="Product Access Code (4 Digits)"
                                    type="number" // Set type to "number"
                                    value={businessDetails?.edit_product_pin}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "edit_product_pin")}
                                    required
                                    variant="outlined"
                                    inputProps={{ maxLength: 4, type: "number" }}
                                    helperText={accessCodeError ? '4 digits only' : ''}
                                />
                            
                            </div>

                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Address Line 1" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "address_line_1")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Address Line 2" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "address_line_2")} variant="outlined" />
                                <TextField id="outlined-controlled" type="number" className="form-control me-3 col" label="Post Code" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "post_code")} required variant="outlined" />
                            </div>

                            <div className='form-group my-2 row'>
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="City" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "city")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="State" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "state")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Country" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "country")} required variant="outlined" />
                            </div>

                            <div className='form-group my-2 row'>
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "email")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Mobile" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "mobile")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Landline" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "landline")} variant="outlined" />
                            </div>

                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="General Message" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "general_message")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <div className="form-check col form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={businessDetails?.is_dealer} onChange={event => handleBusinessDetailsChange(event, "is_dealer")} />
                                    <label className="form-check-label" htmlFor="isDealer">Is a Dealer</label>
                                </div>
                                <div className="form-check col form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={businessDetails?.is_nixconnect_business} onChange={event => handleBusinessDetailsChange(event, "is_nixconnect_business")} />
                                    <label className="form-check-label" htmlFor="isNiconnectBusiness">NixConnect Business</label>
                                </div>
                            </div>
                        </div>                        

                        <label htmlFor='accessInfo' style={{ fontSize: 20, fontWeight: 'bold' }}>Access Info</label>
                        <label htmlFor='accessInfo' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id='accessInfo'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Key" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "key")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Secret" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "secret")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Consumer Pass" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "consumer_pass,")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "name")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Base URL" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "base_url")} required variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Base Path" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "base_path")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Base Version" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "base_version")} required variant="outlined" />
                                <TextField id="outlined-controlled" type="number" className="form-control me-3 col" label="Port" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "port")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <div className="form-check col form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={accessInfo?.is_active_business} onChange={event => handleAccessInfoChange(event, "is_active_business")} disabled={accessInfo?.is_banned_business}/>
                                    <label className="form-check-label" htmlFor="isActiveBusiness">Is Active Business</label>
                                </div>

                                <div className="form-check col form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={accessInfo?.is_banned_business} onChange={event => handleAccessInfoChange(event, "is_banned_business")}/>
                                    <label className="form-check-label" htmlFor="isBannedBusiness">Is Banned Business</label>
                                </div>
                            </div>
                        </div>

                        <label htmlFor='bankDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Bank Details</label>
                        <label htmlFor='bankDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id='bankDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Bank Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "bank_name")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Account Holder Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "account_holder_name")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Account Number" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "account_number")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="BSB/IFSC" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "bsb")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Routing Number" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "routing_number")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Swift Code" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "swift_code")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Iban" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "iban")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Branch Name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "branch_name")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Address" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "address")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_email")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Mobile" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_mobile")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="UPI Payment Id" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "upi_payment_id")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Other" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_other")} variant="outlined" />
                            </div>
                        </div>

                        {/* <div>
                            <div>
                                <label htmlFor='squarePaymentGateway' style={{ fontSize: 20, fontWeight: 'bold' }}>Payment Gateways</label>
                            </div>
                            <div>
                                <label htmlFor='squarePaymentGateway' style={{ fontSize: 18, fontWeight: 'bold' }}>1. Square</label>
                            </div>
                        </div>
                        <div id='squarePaymentGateway'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Gateway Base URL" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "base_url")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Gateway Base Path" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "base_path")} required variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Application ID" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "application_id")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Access Token" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "access_token")} required variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <div className="form-check col form-switch">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        role="switch" 
                                        checked={squarePaymentGateway?.is_active} 
                                        onChange={event => handleSquareGatewayChange(event, "is_active")} 
                                        />
                                    <label className="form-check-label" htmlFor="isActive">
                                        Is Active
                                    </label>
                                </div>
                            </div>
                        </div> */}

                        <label htmlFor='taxDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Tax Details</label>
                        <label htmlFor='taxDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id='taxDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Standard Tax Rate" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "category_one")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="In Percent" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "value_one")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Alternative Tax Rate" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "category_two")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="In Percent" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "value_two")} variant="outlined" />
                            </div>
                            <div className="form-check col form-switch">
                                <input className="form-check-input" type="checkbox" role="switch"  onChange={event => handleTaxDetailsChange(event, "is_composition_tax_scheme")} />
                                <label className="form-check-label" htmlFor="isCompositionTaxScheme">Is your business on the Composition Tax Scheme?</label>
                            </div>
                        </div>

                        <label htmlFor='registrationDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Business Registration Details</label>
                        <label htmlFor='registrationDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id='registrationDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Registration Number / ABN" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRegistrationDetailsChange(event, "company_number")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Additional Company Information" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRegistrationDetailsChange(event, "company_other_details")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Dealer License Number" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRegistrationDetailsChange(event, "dealer_license_number")} variant="outlined" />
                            </div>
                        </div>

                        <label htmlFor='additionalInfo' style={{ fontSize: 20, fontWeight: 'bold' }}>Additional Info</label>
                        <label htmlFor='additionalInfo' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id="additionalInfo">
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Currency Code" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "currency_code")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Currency Symbol" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "currency_symbol")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Notes" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "notes")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Message" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "message")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Remarks" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "remarks")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Return Policy" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "return_policy")} variant="outlined" multiline minRows={3} maxRows={20} />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Thank you Message" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "thank_you_message")} variant="outlined" multiline minRows={3} maxRows={20} />
                            </div>
                            <BorderedSection title={'Business Logo'}>
                                <BusinessLogoUpload businessLogo={businessLogo} setBusinessLogo={setBusinessLogo} />
                            </BorderedSection>
                        </div>


                        {availableFeatures && <Box sx={{ ...commonStyles, borderRadius: 1 }}>
                            <label htmlFor='features' style={{ fontSize: 20, fontWeight: 'bold' }}>Select Features</label>
                            <div id='features'>

                                <input
                                    type="checkbox"
                                    name="features"
                                    id='selectAll'
                                    value={'Select All'}
                                    checked={availableFeatures.length === selectedFeatures.length}
                                    onChange={handleOnChangeFeatures}
                                />
                                <label>Select All</label>
                                {availableFeatures.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            name="features"
                                            id={item.id}
                                            value={item.title}
                                            checked={selectedFeatures.some(selectedFeature => selectedFeature.feature_id === item.id)}
                                            onChange={handleOnChangeFeatures}
                                        />
                                        <label>{item.title}</label>
                                        </div>
                                    );
                                })}

                            </div>
                        </Box>}
                        <div className="button-container">
                            <button type="submit" className="btn btn-primary">Create</button>
                            <button type="reset" className="btn-clear">Clear</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateBusiness;